footer {
    flex-grow: 0;
  }
  #footer_text {
    font-size: 1em;
    padding: 10px;
  }

a {
  color: whitesmoke;
}

a:hover {
  color: rgb(69, 128, 182);
}